import { Typography } from '@/shared';
import { CookieHelper } from '@/shared/helpers/cookie';
import { pushSlug } from '@/shared/helpers/url';
import { useData } from '@/shared/providers/DataProvider';
import clsx from 'clsx';
import { MouseEvent } from 'react';
import styles from './MobileLanguageSwitcher.module.scss';

type MobileLSProps = {
    curLangCode: string;
    curColor: React.CSSProperties;
    isPage: boolean;
};

const MobileLanguageSwitcher: React.FC<MobileLSProps> = ({ curLangCode, curColor, isPage }) => {
    const isEnglish = curLangCode === 'en';
    const slugs = useData().slugs;

    function updateLanguage(e: MouseEvent<HTMLSpanElement>, langcode: string) {
        if (langcode === curLangCode) {
            return;
        }

        const pathname = langcode === 'de' ? slugs.translatedSlugs[0].path : slugs.default;
        pushSlug(pathname, langcode);

        CookieHelper.set('NEXT_LOCALE', langcode, undefined, process.env.NEXT_PUBLIC_COOKIE_DOMAIN);
    }

    return (
        <div className={styles.container} style={curColor}>
            <span onClick={e => updateLanguage(e, 'en')} tabIndex={0} role="button">
                <Typography.P darkTheme={isPage} className={clsx(isEnglish && styles.selected)}>
                    EN
                </Typography.P>
            </span>
            <Typography.P darkTheme={isPage}>|</Typography.P>
            <span onClick={e => updateLanguage(e, 'de')} tabIndex={0} role="button">
                <Typography.P darkTheme={isPage} className={clsx(!isEnglish && styles.selected)}>
                    DE
                </Typography.P>
            </span>
        </div>
    );
};

export default MobileLanguageSwitcher;
