import useScrollProgress from '@/shared/helpers/useScrollProgress';
import { useBreakpoints } from '@/shared/providers/Device';
import useNavbarScroll from '@/shared/providers/useNavbarScroll';
import { MenuLinkStoryblok, NavbarStoryblok } from '@/shared/types/storyblok/storyblok-types';
import { SbBlokData } from '@storyblok/react';
import { useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';
import DesktopNav from './Desktop/Navbar';
import MobileNavbar from './Mobile/MobileNavbar';

type NavbarProps = {
    blok: NavbarStoryblok;
    anchorLinks?: MenuLinkStoryblok[];
    pageType: string;
    articleNav?: SbBlokData[];
};

const Navbar = ({ blok, anchorLinks, pageType, articleNav }: NavbarProps) => {
    const { navbarPosition, handlePageScroll } = useNavbarScroll(0, -90);
    const anchorLinksExist = useMemo(
        () => (anchorLinks && anchorLinks?.length > 0 ? anchorLinks : undefined),
        [anchorLinks],
    );
    const { isXXSmall, isExtraSmall, isSmall } = useBreakpoints();
    const isInBetween = useMediaQuery({ query: '(min-width: 768px) and (max-width: 860px)' }); //tablet
    const isMobile = isXXSmall || isExtraSmall || isSmall || isInBetween;

    const progress = useScrollProgress(handlePageScroll);

    return (
        <>
            {isMobile ? (
                <MobileNavbar blok={blok} pageType={pageType} progress={progress} />
            ) : (
                <DesktopNav
                    blok={blok}
                    articleNav={articleNav || []}
                    anchorLinks={anchorLinksExist || []}
                    scrollValue={navbarPosition}
                    progress={progress}
                />
            )}
        </>
    );
};

export default Navbar;
