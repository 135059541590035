import { RefObject, useCallback, useEffect, useState } from 'react';

const useNavbarScroll = (
    navbarOriginalPosition: number,
    maxNavbarMoveUp: number,
    elementRef?: RefObject<HTMLElement>,
) => {
    const [navbarPosition, setNavbarPosition] = useState(navbarOriginalPosition);
    const [previousScrollPosition, setPreviousScrollPosition] = useState(0);
    const [previousZeroScrollPosition, setPreviousZeroScrollPosition] = useState(0);

    // Effect to reset navbar position when elementRef becomes defined
    useEffect(() => {
        if (elementRef?.current) {
            setNavbarPosition(0); // Reset navbar position
        }
    }, [elementRef?.current]); // Dependency on the current property of elementRef

    const handlePageScroll = useCallback(() => {
        const element = elementRef?.current || document.documentElement;
        const currentScrollPosition = element.scrollTop || window.scrollY;
        const isScrollDirectionUp = previousScrollPosition > currentScrollPosition;

        setPreviousScrollPosition(currentScrollPosition);

        const scrollSpeedAdjustment = 1.5;

        if (isScrollDirectionUp) {
            const relativeScrollUp = previousScrollPosition - currentScrollPosition;
            const updatedNavbarPosition = Math.min(
                navbarOriginalPosition,
                navbarPosition + relativeScrollUp / scrollSpeedAdjustment,
            );
            setNavbarPosition(updatedNavbarPosition);
            if (updatedNavbarPosition === navbarOriginalPosition) {
                setPreviousZeroScrollPosition(currentScrollPosition);
            }
        } else {
            const relativeScrollDown = currentScrollPosition - previousZeroScrollPosition;
            if (relativeScrollDown > 0) {
                const updatedNavbarPosition = Math.max(
                    maxNavbarMoveUp,
                    navbarOriginalPosition - relativeScrollDown / scrollSpeedAdjustment,
                );
                setNavbarPosition(updatedNavbarPosition);
            }
        }
    }, [
        previousScrollPosition,
        previousZeroScrollPosition,
        navbarPosition,
        navbarOriginalPosition,
        maxNavbarMoveUp,
        elementRef,
    ]);

    useEffect(() => {
        const element = elementRef?.current || document.documentElement;

        const onScroll = () => window.requestAnimationFrame(handlePageScroll);
        if (element === document.documentElement) {
            window.addEventListener('scroll', onScroll);
        } else {
            element.addEventListener('scroll', onScroll);
        }

        return () => {
            if (element === document.documentElement) {
                window.removeEventListener('scroll', onScroll);
            } else {
                element.removeEventListener('scroll', onScroll);
            }
        };
    }, [handlePageScroll, elementRef]);

    return { navbarPosition, handlePageScroll, setNavbarPosition };
};

export default useNavbarScroll;
