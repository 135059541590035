import { useCallback, useEffect, useState } from 'react';

const useScrollProgress = (handlePageScroll: () => void) => {
    const [progress, setProgress] = useState(0);

    const handleScroll = useCallback(() => {
        let currentScrollPosition;
        let totalScrollHeight;

        currentScrollPosition = window.scrollY;
        totalScrollHeight = document.documentElement.scrollHeight - window.innerHeight;

        const progressPercentage = (currentScrollPosition / totalScrollHeight) * 100;
        setProgress(progressPercentage);
        handlePageScroll();
    }, [handlePageScroll]);

    useEffect(() => {
        const scrollTarget = window;
        scrollTarget.addEventListener('scroll', handleScroll);
        return () => {
            scrollTarget.removeEventListener('scroll', handleScroll);
        };
    }, [handleScroll]);

    return progress;
};

export default useScrollProgress;
