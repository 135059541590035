import { RefObject, useEffect, useState } from 'react';

export enum ScrollDirection {
    up = 'up',
    down = 'down',
}

export const useScrollDirection = (elementRef?: RefObject<HTMLElement>) => {
    const threshold = 100;
    const [scrollDir, setScrollDir] = useState<ScrollDirection | undefined>(undefined);
    const [hasScrolled, setHasScrolled] = useState(false);

    useEffect(() => {
        const element = elementRef?.current || document.documentElement;

        let previousScrollYPosition = element.scrollTop || window.scrollY;

        const scrolledMoreThanThreshold = (currentScrollYPosition: number) =>
            Math.abs(currentScrollYPosition - previousScrollYPosition) > threshold;

        const isScrollingUp = (currentScrollYPosition: number) => currentScrollYPosition > previousScrollYPosition;

        const updateScrollDirection = () => {
            const currentScrollYPosition = element.scrollTop || window.scrollY;

            if (scrolledMoreThanThreshold(currentScrollYPosition)) {
                const newScrollDirection = isScrollingUp(currentScrollYPosition)
                    ? ScrollDirection.down
                    : ScrollDirection.up;

                if (!hasScrolled && newScrollDirection === ScrollDirection.down) {
                    setHasScrolled(true);
                }

                setScrollDir(newScrollDirection);
                previousScrollYPosition = currentScrollYPosition > 0 ? currentScrollYPosition : 0;
            }
        };

        const onScroll = () => window.requestAnimationFrame(updateScrollDirection);

        // Add the event listener based on the element being monitored
        if (element === document.documentElement) {
            window.addEventListener('scroll', onScroll);
        } else {
            element.addEventListener('scroll', onScroll);
        }

        return () => {
            if (element === document.documentElement) {
                window.removeEventListener('scroll', onScroll);
            } else {
                element.removeEventListener('scroll', onScroll);
            }
        };
    }, [elementRef, hasScrolled]);

    return hasScrolled ? scrollDir : undefined;
};
