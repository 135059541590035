import LanguageSwitcher from '@/components/LanguageSwitcher/Desktop/LanguageSwitcher';
import { MenuLinkStoryblok, MenuStoryblok, NavbarStoryblok } from '@/shared/types/storyblok/storyblok-types';
import { SbBlokData } from '@storyblok/react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import MenuLink from '../ui/MenuLink';
import styles from './Navbar.module.scss';

export type DesktopNavbarProps = {
    blok: NavbarStoryblok;
    scrollValue?: number;
    anchorLinks: MenuLinkStoryblok[];
    articleNav: SbBlokData[];
    progress: number;
};

const DesktopNavbar = ({ blok, scrollValue, anchorLinks, articleNav, progress }: DesktopNavbarProps) => {
    const { content } = blok;
    const refs = useRef<HTMLDivElement[]>([]);
    const [active, setActive] = useState<{ index: number; color: string }>({ index: 0, color: 'transparent' });
    const [hovering, setHovering] = useState<boolean>(false);
    const [resizing, setResizing] = useState<boolean>(false);
    const activeRect = refs.current[active.index]?.getBoundingClientRect();
    const [translateY, setTranslateY] = useState(`translateY(0px)`);

    const activeRectData = useMemo(
        () => ({
            left: activeRect && `${activeRect.left}px`,
            width: activeRect ? `${activeRect.width - 24}px` : '62px',
            backgroundColor: active.color,
        }),
        [activeRect, active.color],
    );
    const [barStyle, setBarStyle] = useState(activeRectData);

    useEffect(() => {
        activeRectData && setBarStyle(activeRectData);
        const timeoutId = setTimeout(() => {
            setHovering(true);
        }, 480);
        return () => {
            clearTimeout(timeoutId);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [active.index, active.color, resizing]);

    const handleHover = useCallback(
        (index: number | undefined, color: string | undefined, inOut?: string) => {
            if (active.index === 5 || active.index === -1 || index === 5) {
                if (index === 5) {
                    if (inOut === 'in') {
                        setBarStyle({
                            ...barStyle,
                            backgroundColor: 'transparent',
                        });
                    }
                }
            } else {
                if (index !== undefined) {
                    const rect = refs.current[index].getBoundingClientRect();
                    setBarStyle({
                        left: `${rect.left}px`,
                        width: `${rect.width - 24}px`,
                        backgroundColor: color || 'transparent',
                    });
                } else {
                    setBarStyle(activeRectData);
                }
            }
        },
        [setBarStyle, activeRectData, active.index, barStyle],
    );

    const handleActive = useCallback((index: number, color: string | undefined) => {
        setActive({ index, color: color || 'transparent' });
    }, []);

    useEffect(() => {
        let timeoutId: number | undefined;
        const handleResize = () => {
            setResizing(true);
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            timeoutId = window.setTimeout(() => {
                setResizing(false);
            }, 0);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, []);

    let linkCounter = 0;

    useEffect(() => {
        setTranslateY(`translateY(${scrollValue}px)`);
    }, [scrollValue]);

    return (
        <div className={styles.root}>
            <div className={styles.controller} style={{ transform: translateY }}>
                <div className={styles.wrapper}>
                    <div className={styles.navigation}>
                        {content?.map((nestedBlok: MenuStoryblok) => (
                            <div
                                className={styles.menu}
                                style={{ flexDirection: nestedBlok.flexDirection }}
                                key={nestedBlok._uid}
                            >
                                {nestedBlok.link?.map((nestedBlok: MenuLinkStoryblok) => {
                                    const currentIndex = linkCounter++;
                                    return (
                                        <MenuLink
                                            className={styles.menuLink}
                                            blok={nestedBlok}
                                            key={nestedBlok._uid}
                                            index={currentIndex}
                                            ref={(el: HTMLDivElement) => {
                                                if (el) {
                                                    refs.current[currentIndex] = el;
                                                }
                                            }}
                                            hovering={hovering}
                                            underline={active.index === 5 || active.index === -1}
                                            onHover={handleHover}
                                            onActive={handleActive}
                                        />
                                    );
                                })}
                            </div>
                        ))}
                        {!resizing && <div className={styles.underbar} style={barStyle} />}
                    </div>
                    <LanguageSwitcher />
                </div>
            </div>
           
        </div>
    );
};

export default DesktopNavbar;
