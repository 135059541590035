import { SlugType } from '@/components/Metatags/Metatags';
import { CookieHelper } from '@/shared/helpers/cookie';
import { pushSlug } from '@/shared/helpers/url';
import { useData } from '@/shared/providers/DataProvider';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import { Fragment, MouseEvent, createRef, useEffect, useMemo, useRef, useState } from 'react';
import styles from './LanguageSwitcher.module.scss';
import NextLink from 'next/link';

const LanguageSwitcher = () => {
    const slugs = useData().slugs;

    const languages = ['en', 'de'];
    const router = useRouter();
    const cookieLocale = CookieHelper.get('NEXT_LOCALE');
    const [activeIndex, setActiveIndex] = useState(languages.indexOf(router.locale ?? (cookieLocale as string)));
    const [hoverIndex, setHoverIndex] = useState<number | null>(null);
    const { from } = router.query;
    const refs = useRef(Array.from({ length: languages.length }, () => createRef<HTMLDivElement>()));
    const activeRect = refs.current[hoverIndex ?? activeIndex]?.current?.getBoundingClientRect();
    const wrapperRef = useRef<HTMLDivElement | null>(null);

    const activeRectData = useMemo(
        () => ({
            left: activeRect && `${activeRect.left - 1090}px`,
            width: '25px',
        }),
        [activeRect],
    );

    const [barStyle, setBarStyle] = useState(activeRectData);

    useEffect(() => {
        activeRectData && setBarStyle(activeRectData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [router.locale]);

    useEffect(() => {
        const activeRect = refs.current[hoverIndex ?? activeIndex]?.current?.getBoundingClientRect();
        if (wrapperRef.current && activeRect) {
            const wrapperRect = wrapperRef.current.getBoundingClientRect();
            const adjustedLeft = activeRect.left - wrapperRect.left;
            setBarStyle({
                left: `${adjustedLeft}px`,
                width: '25px',
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [router.locale, hoverIndex]);

    useEffect(() => {
        setHoverIndex(languages.indexOf(router.locale ?? (cookieLocale as string)));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cookieLocale, router.locale, from]);

    const onSelectLang = (lang: string, pathname: string) => {
        if (lang === router.locale) {
            return;
        }
        if (slugs.forbiddenPages?.includes(lang)) {
            if (!!router.query.from) return;
            router.push(`${router.asPath}?from=${lang}`);
        } else {
            pushSlug(pathname, lang);
            CookieHelper.set('NEXT_LOCALE', lang, undefined, process.env.NEXT_PUBLIC_COOKIE_DOMAIN);
            setActiveIndex(languages.indexOf(lang));
        }
    };

    useEffect(() => {
        if (router.locale !== cookieLocale) {
            CookieHelper.set(
                'NEXT_LOCALE',
                (router.locale === 'default' ? 'en' : router.locale || 'en') || (cookieLocale as string),
                undefined,
                process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [router.locale]);
    return (
        <div className={styles.wrapper} ref={wrapperRef}>
            {languages?.map((lang, i) => {
                const slug = slugs?.translatedSlugs?.find((slug: SlugType) => slug?.lang === lang);
                const pathname = `/${router.asPath === '/' ? '' : lang === 'en' ? slugs?.default : slug?.path}`;
                return (
                    <Fragment key={lang}>
                        <div ref={refs.current[i]}>
                            <div
                                onClick={() => onSelectLang(lang, pathname === '/notfound' ? router.asPath : pathname)}
                                className={clsx(styles.languages)}
                                onMouseEnter={() => setHoverIndex(i)}
                                onMouseLeave={() => setHoverIndex(null)}
                            >
                                <div className={clsx(styles.language)}>{lang.toUpperCase()}</div>
                                {i < languages.length - 1 ? <span style={{ margin: '0 4px -1px' }}>/</span> : null}
                            </div>
                            <div className={styles.underbar} style={barStyle} />
                        </div>
                    </Fragment>
                );
            })}
        </div>
    );
};

export default LanguageSwitcher;
