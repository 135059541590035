import { SlugType } from '@/components/Metatags/Metatags';
import { removeLangPrefix } from '@/shared/helpers/sort';
import { useData } from '@/shared/providers/DataProvider';
import { MenuLinkStoryblok, MultilinkStoryblok } from '@/shared/types/storyblok/storyblok-types';
import { storyblokEditable } from '@storyblok/react';
import clsx from 'clsx';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { forwardRef, useEffect, useMemo, useState } from 'react';
import styles from './UiNavbar.module.scss';

type MenuLinkProps = {
    blok: MenuLinkStoryblok | MultilinkStoryblok;
    className?: string;
    index: number;
    hovering?: boolean;
    underline?: boolean;
    onHover?: (index: number | undefined, color?: string, inOut?: string) => void;
    onActive?: (index: number, color?: string, inOut?: string) => void;
};

declare module 'react' {
    interface CSSProperties {
        '--hover-color'?: string;
    }
}

const MenuLink = forwardRef<HTMLDivElement, MenuLinkProps>(
    ({ blok, className, index, hovering, underline, onHover, onActive }: MenuLinkProps, ref) => {
        const { link, name, color } = blok;
        const { data, pageType } = useData();
        const lastPageSlug = data?.lastPageSlugs as any;
        const router = useRouter();
        const { query } = router;
        const { asPath, locale } = router;
        const realSlug = removeLangPrefix(link?.story?.full_slug);
        const [hover, setHover] = useState<boolean>(false);
        const useColor = `#${color}`;
        const [isClient, setIsClient] = useState<boolean>(false);

        const oldPageUrl = useMemo(() => {
            if (locale === 'en' || locale === 'default') {
                return lastPageSlug?.default;
            }
            const matchingSlug = lastPageSlug?.translatedSlugs.find((slug: SlugType) => slug.lang === locale);
            return matchingSlug ? matchingSlug.path : lastPageSlug?.default;
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [locale]);

        const isActive = useMemo(() => {
            const { cached_url, story } = link || {};
            if (pageType === 'notfound') return false;

            if (pageType === 'page') {
                const pathWithLocale = asPath === '/' ? locale : `${locale}${asPath}`;
                let slugWithLocale;
                if (cached_url === '' || cached_url === `/${locale}/`) {
                    slugWithLocale = locale;
                } else if (locale === 'en' && story?.full_slug) {
                    slugWithLocale = `en/${story.full_slug}`;
                } else {
                    slugWithLocale = story?.full_slug;
                }

                return (
                    pathWithLocale?.split('#')[0].replace(/\/$/, '') == slugWithLocale?.split('#')[0].replace(/\/$/, '')
                );
            } else if (lastPageSlug) {
                if ((lastPageSlug?.default === 'home' && !realSlug) || '/' + oldPageUrl === realSlug) {
                    return true;
                }
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [link, router, realSlug, pageType, query, data?.lastPageSlugs]);
        useEffect(() => {
            setIsClient(true);
        }, []);
        useEffect(() => {
            isActive && onHover?.(index, useColor, 'in');
            isActive && onHover?.(index, useColor, 'out');
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [query]);
        useEffect(() => {
            isActive && onActive?.(index, useColor);
        }, [isActive, onActive, color, index, useColor, oldPageUrl]);

        const handleMouseEnter = () => {
            setHover(true);
            onHover?.(index, useColor, 'in');
        };
        const handleMouseLeave = () => {
            setHover(false);
            onHover?.(undefined, useColor, 'out');
        };

        const linkStyle = useMemo(() => {
            return isClient
                ? {
                      '--hover-color': (isActive && !hovering) || (hover && underline) ? useColor : 'transparent',
                      '--timing': underline ? '0.8s' : '0s',
                  }
                : {};
        }, [isActive, hovering, underline, hover, useColor, isClient]);
        const anchor = link?.anchor ? `#${link?.anchor}` : '';

        return (
            <Link
                scroll={true}
                className={clsx(styles.rtl, className, isClient && isActive && styles.active)}
                onPointerEnter={handleMouseEnter}
                onPointerLeave={handleMouseLeave}
                style={linkStyle}
                href={`${link?.story?.full_slug ?? '/'}${anchor}`}
                as={link?.story?.full_slug ?? '/'}
            >
                <div
                    id={`navbarLink-${index}`}
                    {...storyblokEditable(blok)}
                    ref={ref}
                    className={clsx(styles.link, styles.hover)}
                >
                    {name}
                </div>
            </Link>
        );
    },
);
MenuLink.displayName = 'MenuLink';

export default MenuLink;
